<template>
  <TopMenuStyle>
 
  <sdDropdown>
    <template #overlay>

      <router-link to="/centers" v-if="disCenter"><span>Add region</span></router-link>
      <router-link to="/users" v-if="disUser"><span>Add user</span></router-link>
      <router-link to="/roles" v-if="disRole"><span>Add role</span></router-link>
      <router-link to="/assign"><span >Assign/Reassign leads</span></router-link>
      <router-link to="/new"><span >New leads</span></router-link>
      <router-link to="/fresh-data"><span >Add contacts</span></router-link>
      <router-link to="/data-update"><span >Update contacts</span></router-link>
      <router-link to="/clicks-update"><span >Add Clicks/Opens</span></router-link>
    </template>
      <router-link to="#" style="color:#ffffff;font-size: 16px;">Administrator <sdFeatherIcons type="chevron-down" size="16" style="color:#ffffff; margin-right:20px !important;"/></router-link>
  </sdDropdown>
  <sdDropdown>
    <template #overlay>
      <router-link to="/" v-if="disTask"><span >Dashboard</span></router-link>
      <router-link to="/search" v-if="dispSearch"><span >Search Leads</span></router-link>
      <router-link to="/new-leads"><span >Add lead</span></router-link>
    </template>
      <router-link to="#" style="color:#ffffff;font-size: 16px;">Sales Leads Process <sdFeatherIcons type="chevron-down" size="16" style="color:#ffffff; margin-right:20px !important;"/></router-link>
  </sdDropdown>
  <sdDropdown>
    <template #overlay>
      <router-link to="/daily"><span >New Database Additions Report</span></router-link>
      <router-link to="/status"><span >Leads Assigned to Individuals Report</span></router-link>
      <router-link to="/select-status"><span >Leads Individual Status Report</span></router-link>
      <router-link to="/status-changed"><span >Leads Status changed over a period Report</span></router-link>
      <router-link to="/follow-up"><span >Leads Pending Follow-ups in a period Report</span></router-link>
      <router-link to="/clicks-tag"><span >Daily Leads Tagged Report</span></router-link>
    </template>
      <router-link to="#" style="color:#ffffff;font-size: 16px;" v-if="disReport">Reports <sdFeatherIcons type="chevron-down" size="16" style="margin-top: 100px; color:#ffffff; margin-right:20px !important;"/></router-link>
  </sdDropdown>
   <!--<sdDropdown>
    <template #overlay>
      <router-link to="#"><span >Campaign Data Upload</span></router-link>
      <router-link to="#"><span >Campaign data downloads</span></router-link>
    </template>
      <router-link to="#" style="color:#ffffff;font-size: 16px;">Campaign <sdFeatherIcons type="chevron-down" size="16" style="margin-top: 100px; color:#ffffff; margin-right:20px !important;"/></router-link>
  </sdDropdown>-->
  <sdDropdown>
    <template #overlay>
      <router-link to="/send-rfp"><span >Send Request for Proposal</span></router-link>
    </template>
      <router-link to="#" style="color:#ffffff;font-size: 16px;">RFP Questionnaire<sdFeatherIcons type="chevron-down" size="16" style="margin-top: 100px; color:#ffffff; margin-right:20px !important;"/></router-link>
  </sdDropdown>
  </TopMenuStyle>
</template>
<script>
import { onMounted, defineComponent,ref} from "vue";
import { useStore } from "vuex";
import VueTypes from 'vue-types';
import { useRouter } from "vue-router";
import { TopMenuStyle } from "./style";
import Dropdown from "../components/dropdown/Dropdown";
export default defineComponent({
  name: "TopMenu",
  props:["currentuser"],
  watch: {
    currentuser: function (newVal, oldVal) {
      // watch it
     
    },
  },
  components: {
    TopMenuStyle,
    Dropdown,
  },
  setup() {
  var disCenter = ref(false);
    var disRole = ref(false);
    var disUser = ref(false);
    var disReport = ref(false);
    var disTask = ref(false);
    var dispSearch = ref(false);
    var currentuser = JSON.parse(localStorage.getItem("user"));
    onMounted(() => {
      disNav();
      const active = document.querySelector(".strikingDash-top-menu a.active");
      const activeDefault = () => {
        const megaMenu = active.closest(".megaMenu-wrapper");
        const hasSubMenuLeft = active.closest(".has-subMenu-left");
        if (!megaMenu) {
          active.closest("ul").previousSibling.classList.add("active");
          if (hasSubMenuLeft)
            hasSubMenuLeft
              .closest("ul")
              .previousSibling.classList.add("active");
        } else {
          active
            .closest(".megaMenu-wrapper")
            .previousSibling.classList.add("active");
        }
      };

      window.addEventListener("load", active && activeDefault);
      
      // return () => window.removeEventListener('load', activeDefault);
    });
    
    const addParentActive = (event) => {
      document.querySelectorAll(".parent").forEach((element) => {
        element.classList.remove("active");
      });

      const hasSubMenuLeft = event.currentTarget.closest(".has-subMenu-left");
      const megaMenu = event.currentTarget.closest(".megaMenu-wrapper");
      if (!megaMenu) {
        event.currentTarget
          .closest("ul")
          .previousSibling.classList.add("active");
        if (hasSubMenuLeft)
          hasSubMenuLeft.closest("ul").previousSibling.classList.add("active");
      } else {
        event.currentTarget
          .closest(".megaMenu-wrapper")
          .previousSibling.classList.add("active");
      }
    };
    const disNav = () => {
   
     let role = JSON.parse(localStorage.getItem("usr_role"));
      
      //alert(role.role_id);
      if (role.role_id=="1" || role.role_id=="2") {
            disCenter.value=true;
            disRole.value=true;
            disUser.value=true;
            disReport.value=true;
            disTask.value=true;
            dispSearch.value=true;
      } 
      else if(role.role_id=="3") {
        disTask.value=true;
        dispSearch.value=true; 
      }
      else if (role.role_id=="4" || role.role_id=="5"){
            disCenter.value=false;
            disRole.value=false;
            disUser.value=false;
            disReport.value=false;
            disTask.value=false;
            dispSearch.value=true;
      }
    };
    return { 
    addParentActive,
    disCenter,
    disRole,
    disUser,
    disReport,
    disTask,
    dispSearch,
    
    };
  },
});
</script>
